
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

import { IItem, IItemUpdate, ILabel, ILabelCreate } from "@/interfaces";

import { readModel, readFirstLabelContainer, readLabels } from "@/store/model/getters";
import { dispatchGetModels, dispatchCreateLabel } from "@/store/model/actions";

import TextHighlight from "vue-text-highlight";

@Component({
  components: {
    TextHighlight,
  },
})
export default class ItemPreviewMultiLabel extends Vue {
  // internal properties
  @Prop({ default: false }) public item!: IItem;
  @Prop({ default: false }) public targetItems?: IItemUpdate[];
  @Prop({ default: true }) public flat!: boolean;
  @Prop({ default: false }) public change!: boolean;
  @Prop({ default: false }) public single!: boolean;
  @Prop({}) public highlights!: string[];
  @Prop({}) public chosenLabels!: number[];
  @Prop({}) public recommendation: string;
  public hide = false;
  public addLabel = false;
  /**
   * upload event...
   */

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  public id: number = 1;
  public labelDescription: string = "";
  public labelName: string = "";
  public labelExamples: string[] = [];
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeLabelExample: string = "";
  public loading: boolean = false;
  public e6: number = 0;
  public uploadError: any = null;
  public color: string = "green";
  public colors: string[] = [
    "purple",
    "pink",
    "deep-purple",
    "red",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
  ];

  public mounted() {}
  get colorList() {
    if (this.remainingColors.length > 0) {
      this.color = this.remainingColors[0];
      return this.remainingColors;
    } else {
      this.color = this.colors[Math.floor(Math.random() * this.color.length)];
      return this.colors;
    }
  }

  get remainingColors() {
    let remainingColors: string[] = [];
    if (this.labels) {
      this.colors.forEach((c) => {
        const colorExists = this.labels!.filter((label) => label.color === c);
        if (colorExists.length === 0) {
          remainingColors.push(c);
        }
      });
    } else {
      remainingColors = this.colors;
    }

    return remainingColors;
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  public labelById(labelId) {
    return this.labels.filter((label) => label.id === labelId);
  }

  public async getModels() {
    await dispatchGetModels(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
  }

  public removeLabelExample(toRemove) {
    let tmpExamples = this.labelExamples.slice();
    tmpExamples = tmpExamples.filter((example) => example !== toRemove);
    this.labelExamples = tmpExamples;
  }

  public addLabelExample() {
    if (this.activeLabelExample === "") {
      this.createLabelAPI();
    } else if (!this.labelExamples.includes(this.activeLabelExample)) {
      this.labelExamples.push(this.activeLabelExample);
      this.activeLabelExample = "";
    }
  }

  get labelNameExists(): boolean {
    if (this.labels) {
      return this.labels!.filter((label) => label.name === this.labelName).length > 0;
    }
    return false;
  }

  get labelNameErrors(): string[] {
    if (this.labelNameExists) {
      return ["This name does already exist"];
    }
    return [];
  }

  get labelDescriptionErrors(): string[] {
    if (this.labelDescription.length > 250) {
      return ["Label description is too long"];
    }
    return [];
  }

  get labelIsClean(): boolean {
    if (
      this.labelName.length < 1 ||
      this.labelName.length > 40 ||
      this.labelDescription.length > 250
    ) {
      return false;
    }
    if (this.labelNameExists) {
      return false;
    }
    return true;
  }
  public async createLabelAPI() {
    this.addLabel = false;

    if (this.activeLabelExample.length) {
      this.labelExamples.push(this.activeLabelExample);
    }
    this.activeLabelExample = "";
    const newLabel: ILabelCreate = {
      name: this.labelName,
      description: this.labelDescription,
      color: this.color,
      examples: this.labelExamples,
    };
    await dispatchCreateLabel(this.$store, {
      labelContainerId: this.firstLabelContainer!.id,
      label: newLabel,
    })
      .then((r) => {
        setTimeout(() => {
          const ids: number[] = this.labels.map((l) => {
            return l.id;
          });
          const going = Math.max(...ids);
          this.labelDescription = "";
          this.labelName = "";
          this.uploadError = null;
          this.labelExamples = [];
          if (this.labels.length === 2 && this.firstLabelContainer!.type === "single") {
            this.changed(0, 0, 0);
          }
          // this.$router.push({
          //   path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/
          // ${this.$router.currentRoute.params.id}/label/labels/${going}/example`,
          // });
        }, 200);
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError);
        this.uploadError = uploadError.response;
      });
  }

  get getTarget() {
    // if (this.hide) {
    //   return {
    //     color: "grey",
    //     name: "ignore",
    //   };
    // }
    const filteredItems = this.targetItems!.filter((targetItem) => targetItem.id === this.item.id);
    if (filteredItems.length > 0) {
      const labelValues: number[] = [];

      filteredItems[0].user_labels.forEach((entry) => {
        if (typeof entry.label === "number") {
          labelValues.push(entry.label);
        }
      });
      return labelValues;
    }
    const labelValues: number[] = [0];
    return labelValues;
  }

  public glowOrNot(label) {
    if (this.item.hasOwnProperty("predictions") && this.item.predictions!.length) {
      const predictions = this.item!.predictions!;
      const filteredItem = predictions!.filter((targetItem) => targetItem.label === label.id)[0];
      if (filteredItem && filteredItem.prediction >= 0.47) {
        return "glowbox-button";
      }
    }
    return "";
  }

  get sortedLabels() {
    if (this.labels) {
      const sorted = this.labels!.map((o) => ({ ...o }));
      if (this.item.hasOwnProperty("predictions") && this.item.predictions!.length) {
        const predictions = this.item!.predictions!;

        if (sorted.length !== predictions.length) {
          const predictionIds: number[] = [];
          predictions.forEach((element) => {
            predictionIds.push(element.label);
          });
          sorted.forEach((label) => {
            if (!predictionIds.includes(label.id)) {
              predictions.unshift({ label: label.id, prediction: 0 });
            }
          });
        }
      }
      return sorted;
    }
    return this.labels;
  }

  get hideThis() {
    if (this.hide) {
      return "background-color: rgb(255 198 198);";
    }
    return "";
  }

  public getLabelById(id) {
    const filteredItems = this.labels!.filter((label) => label.id === id);
    if (filteredItems.length > 0) {
      return filteredItems[0];
    }
    return 0;
  }

  @Emit()
  public changed(labelId, itemId, storeId) {}
}
